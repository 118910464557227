/**
 * 程序名：api接口
 * 功能：与后端通讯的api接口定义
 */
import request from '@/utils/request';


// 示例 具体实现根据实际接口进行变动
const api = {
  getTokenByCode: '/auth/queryToken', // 获取api访问认证token
  getUserInfo: '/gateway/account/eco/getUserInfo', // 获取当前登录用户信息
  logout: '/gateway/auth/logout', // 登出

  getActivityEndtime:'/wx/activity_time_get', // 获取活动的结束时间
  getEccomTokenFromMarket: '/wx/get_token', // 从市场活动获取统一认证的token
  getUserIdByEcoId: '/api/user/getUserIdByUserAccount',

  getToken: '/auth/getSystemToken',
  getMarketToken: '/auth/getMserviceToken', // 获取市场活动接口调用的token

  addEccomAdministrator: '/api/administrator/addEccomAdministrator', // 添加华讯用户为管理员
  whetherAdministrator: '/api/administrator/whetherAdministrator', // 判断是否是管理员
  updateAdministrator: '/api/administrator/updateAdministrator', // 修改管理员身份
  updateCreator: '/api/paper/updateCreator', // 修改问卷创建人
  getPaper: '/api/paper/getPapers',
  postPaper: '/api/paper/addPaper',
  updatePaper: '/api/paper/updatePaper',
  deletePaper: '/api/paper/delPaper',
  publishPaper: '/api/paper/publishPaper',
  pausePaper: '/api/paper/pausePaper',
  getPaperState: '/api/paper/getPaperState',
  saveExpirationTime: '/api/paper/saveExpirationTime',
  paperIsExist: '/api/paper/paperIsExist',
  paperIsExistByPaperId: '/api/paper/paperIsExistByPaperId',
  existPaper: '/api/paper/existPaper',
  isFillPaper: '/api/paper/isFillPaper',

  getSimilarQuestions: '/api/library/getSimilarQuestionsByTagAndTitle',
  getQuestionsByPaperId: '/api/question/getQuestionsByPaperId',
  addQuestion: '/api/question/addQuestion',
  updateQuestion: '/api/question/updateQuestion',
  delQuestion: '/api/question/delQuestion',
  addQuestionFromLibrary: '/api/question/addQuestionFromLibrary',

  addTag: '/api/tag/addTag',
  getTags: '/api/tag/getTags',
  getTagsByIds: '/api/tag/getTagsByIds',

  saveAnswer: '/api/answer/saveAnswer',

  getLotteryId: '/api/lottery/getLotteryIdByPaperId',
  createLottery: '/api/lottery/createLottery',
  saveOrUpdateAward: '/api/award/updateAwards',
  getPrize: '/api/award/getAwardsByLotteryId',
  deleteAwards: '/api/award/deleteAwards',
  getUserAccountAndName: '/api/user/getUserAcountAndName',
  addOrUpdateUser: '/api/user/addOrUpdateUser',

  getLottery: '/api/lottery/getLottery',
  updateLottery: '/api/lottery/updateLottery',
  getLotteryDemo: '/api/lottery/getLotteryDemo',
  startLottery: '/api/lottery/startLottery',
  stopOrStartLottery: '/api/lottery/stopOrStartLottery',
  saveDeliverInfo: '/api/punter/saveDeliverInfo',
  whetherLottery: '/api/userLottery/whetherLottery',

  getDataByPaperId: '/api/paper/getDataByPaperId',
  getLotteryDataByPaperId: '/api/paper/getLotteryDataByPaperId',
}

// 通过paperId判断是否有问卷
export function paperIsExistByPaperId(parameter) {
  return request({
    url: api.paperIsExistByPaperId,
    method: 'get',
    params: parameter,
  })
}

// 添加标签
export function addTag(parameter) {
  return request({
    url: api.addTag,
    method: 'post',
    data: parameter,
  })
}

// 获取活动的结束时间
export function getActivityEndtime(parameter, token) {
  return request({
    url: api.getActivityEndtime,
    method: 'get',
    params: parameter,
    headers: {
      'Authorization': `JWT ${token}`,
    }
  })
}

// 判断用户是否填写过问卷
export function isFillPaper(parameter) {
  return request({
    url: api.isFillPaper,
    method: 'get',
    params: parameter,
  })
}

// 从题库中批量添加问题
export function addQuestionFromLibrary(parameter) {
  return request({
    url: api.addQuestionFromLibrary,
    method: 'post',
    data: parameter,
  })
}

// 判断用户是否抽过奖
export function whetherLottery(parameter) {
  return request({
    url: api.whetherLottery,
    method: 'get',
    params: parameter,
  })
}

// 通过活动id和类型判断是否存在问卷，存在则返回问卷的id
export function existPaper(parameter) {
  return request({
    url: api.existPaper,
    method: 'get',
    params: parameter,
  })
}

// 通过活动id判断是否存在已发布的预报名和反馈问卷，存在则同时返回问卷的id
export function paperIsExist(parameter) {
  return request({
    url: api.paperIsExist,
    method: 'get',
    params: parameter,
  })
}

//通过华讯id获取用户在数据库的id
export function getUserIdByEcoId(parameter) {
  return request({
    url: api.getUserIdByEcoId,
    method: 'get',
    params: parameter,
  })
}

// 保存问卷的过期时间
export function saveExpirationTime(parameter) {
  return request({
    url: api.saveExpirationTime,
    method: 'put',
    params: parameter,
  })
}


// 使用code获取api访问认证token
export function getTokenByCodeApi(parameter) {
  return request({
    url: api.getTokenByCode,
    method: 'post',
    data: parameter
  })
}

// 获取当前登录用户信息
export function getUserInfoApi(token) {
  return request({
    url: api.getUserInfo,
    method: 'post',
    headers: {
      'Authorization': token,
    },
  })
}

// 登出
export function logoutApi(token) {
  return request({
    url: api.logout,
    method: 'post',
    headers: {
      'Authorization': token,
    },
  })
}

// 获取市场活动的token
export function getMarketTokenApi() {
  return request({
    url: api.getMarketToken,
    method: 'get'
  })
}

// 通过市场活动获取认证系统token
export function getEccomTokenFromMarketApi(parameter, token) {
  return request({
    url: api.getEccomTokenFromMarket,
    method: 'get',
    params: parameter,
    headers: {
      'Authorization': token,
    },
  })
}


// 获取本系统的认证token
export function getTokenApi() {
  return request({
    url: api.getToken,
    method: 'get'
  })
}

// 判断是否是管理员
export function whetherAdministrator(parameter) {
  return request({
    url: api.whetherAdministrator,
    method: 'get',
    params: parameter,
  })
}

// 修改管理员身份
export function updateAdministrator(parameter) {
  return request({
    url: api.updateAdministrator + `?userEcoEmail=${parameter.userEcoEmail}&admin=${parameter.admin}`,
    method: 'put'
  })
}

// 添加华讯用户为管理员
export function addEccomAdministrator(parameter) {
  return request({
    url: api.addEccomAdministrator,
    method: 'post',
    data: parameter
  })
}

// 修改问卷创建人
export function updateCreator(parameter) {
  return request({
    url: api.updateCreator,
    method: 'get',
    params: parameter,
  })
}

// 获取问卷状态
export function getPaperState(parameter) {
  return request({
    url: api.getPaperState,
    method: 'get',
    params: parameter,
  })
}

// 获取用户的id+name
export function getUserAccountAndName() {
  return request({
    url: api.getUserAccountAndName,
    method: 'get',
  })
}

// 添加用户信息
export function addOrUpdateUser(parameter) {
  return request({
    url: api.addOrUpdateUser,
    method: 'post',
    data: parameter
  })
}

// 保存收货信息
export function saveDeliverInfo(parameter, data) {
  return request({
    url: api.saveDeliverInfo,
    method: 'put',
    data: data,
    params: parameter,
  })
}

// 获取抽奖结果信息
export function getLotteryDataByPaperId(parameter) {
  return request({
    url: api.getLotteryDataByPaperId,
    method: 'get',
    params: parameter,
  })
}

// 获取问卷统计信息
export function getDataByPaperId(parameter) {
  return request({
    url: api.getDataByPaperId,
    method: 'get',
    params: parameter,
  })
}

// 暂停\开始抽奖活动
export function stopOrStartLottery(parameter) {
  return request({
    url: api.stopOrStartLottery,
    method: 'put',
    params: parameter,
  })
}

// 获取抽奖信息
export function getLottery(parameter) {
  return request({
    url: api.getLottery,
    method: 'get',
    params: parameter,
  })
}

// 更新抽奖活动信息
export function updateLottery(parameter) {
  return request({
    url: api.updateLottery,
    method: 'put',
    data: parameter,
  })
}

// 开始抽奖
export function startLottery(parameter) {
  return request({
    url: api.startLottery,
    method: 'put',
    data: parameter,
  })
}

// 获取抽奖页面的九宫格布局
export function getLotteryDemo(parameter) {
  return request({
    url: api.getLotteryDemo,
    method: 'get',
    params: parameter,
  })
}

// 删除奖品
export function deleteAwards(parameter) {
  return request({
    url: api.deleteAwards,
    method: 'put',
    data: parameter,
  })
}

// 获取抽奖活动的奖品
export function getPrize(parameter) {
  return request({
    url: api.getPrize,
    method: 'get',
    params: parameter,
  })
}

// 添加或更新奖品
export function saveOrUpdateAward(parameter) {
  return request({
    url: api.saveOrUpdateAward,
    method: 'post',
    data: parameter,
  })
}

// 问卷添加抽奖活动
export function createLottery(parameter) {
  return request({
    url: api.createLottery,
    method: 'post',
    data: parameter,
  })
}

// 获取抽奖活动的ID
export function getLotteryId(parameter) {
  return request({
    url: api.getLotteryId,
    method: 'get',
    params: parameter,
  })
}

// 保存用户的回答
export function saveAnswer(parameter) {
  return request({
    url: api.saveAnswer,
    method: 'post',
    data: parameter,
  })
}

// 通过id获取标签
export function getTagsByIds(parameter) {
  return request({
    url: api.getTagsByIds,
    method: 'get',
    params: parameter,
  })
}

// 获取标签
export function getTags() {
  return request({
    url: api.getTags,
    method: 'get',
  })
}

// 删除问题
export function delQuestion(parameter) {
  return request({
    url: api.delQuestion,
    method: 'delete',
    params: parameter
  })
}

// 修改问题
export function updateQuestion(parameter) {
  return request({
    url: api.updateQuestion,
    method: 'put',
    data: parameter
  })
}

// 添加问题
export function addQuestion(parameter) {
  return request({
    url: api.addQuestion,
    method: 'post',
    data: parameter
  })
}

// 获取问卷中的问题
export function getQuestionsByPaperId(parameter) {
  return request({
    url: api.getQuestionsByPaperId,
    method: 'get',
    params: parameter
  })
}

// 获取问卷列表
export function getPaperList(parameter) {
  return request({
    url: api.getPaper,
    method: 'get',
    params: parameter
  })
}

// 创建问卷
export function createPaper(parameter) {
  return request({
    url: api.postPaper,
    method: 'post',
    data: parameter
  })
}

// 修改问卷
export function updatePaper(parameter) {
  let formData = new FormData();
  Object.keys(parameter).forEach(key => {
    formData.append(key, parameter[key]);
  })
  return request({
    url: api.updatePaper,
    method: 'put',
    data: formData
  })
}

// 删除问卷
export function deletePaper(parameter) {
  return request({
    url: api.deletePaper,
    method: 'delete',
    params: parameter
  })
}

// 题库查询
export function getSimilarQuestions(parameter) {
  return request({
    url: api.getSimilarQuestions,
    method: 'get',
    params: parameter
  })
}

// 发布问卷
export function publishPaper(parameter) {
  return request({
    url: api.publishPaper,
    method: 'put',
    params: parameter
  })
}

// 暂停问卷
export function pausePaper(parameter) {
  return request({
    url: api.pausePaper,
    method: 'put',
    params: parameter
  })
}